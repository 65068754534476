@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}
.banner{
  background-image: url(/static/media/novo-fundo.f4e27f6a.jpg);
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 35% bottom 65%;
  display: flex;
  align-items: flex-end;
  padding: 50px;
  background-color: black;
}
.banner-info{
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
  padding: 40px;
  border-radius: 10px;
}
.banner-info h1{
  font-size: 40px;
  color: #fff;
  width: 460px;
}
.banner-info cite{
  color: #B2B2B2;
  margin: 15px 0;
}
.banner-info button{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  width: 200px;
  height: 40px;
  background-color: #a73232;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  transition: all ease 0.3s;
  margin-top: 10px;
}
.banner-info button:hover{
  background-color: #832020;
  width: 220px;
  cursor: pointer;
}
@media (max-width: 375px) {
  *{
    margin: 0;
    padding: 0;
  }
  .banner{
    width: 100%;
    padding: 0;
  }
  .banner-info{
    margin-bottom: 0;
  }
  .banner-info h1{
    margin-top: 200px;
    width: 100%;
  }
}
footer{
  width: 100%;
  background-color: #e2e2e2;
  color: rgb(20, 19, 19);
  border-top: 0.1em solid #989898;
}
.footer-content{
  display: flex;
  padding: 40px 20px;
}
.footer-item{
  padding: 20px;
  width: 100%;
  margin-top: 30px;
}
.footer-item h2{
  margin-bottom: 20px;
}
.footer-item p{
 text-align: justify;
}
.footer-item address{
  font-weight: bold;
  margin-top: 20px;
}
.phone-item{
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-weight: normal;
}
.phone-footer-icon{
  margin-right: 10px;
}
.footer-item ul{
  list-style-type: none;
}
.footer-item ul li{
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #232323;
  transition: 0.3s;
  color: #000000;
}
.footer-item ul li:hover{
  color: #a73232;
  cursor: pointer;
}
.footer-copyright{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  font-weight: normal;
  color: #000;
  border-top: 0.1em solid #989898;
}
.footer-icons{
  margin-right: 10px;
  display: flex;
}
.footer-icons a{
  color: #000000;
}
.footer-icon{
  margin-right: 20px;
  transition: 0.3s;
  cursor: pointer;
}
.footer-icon:hover{
  color: #a73232;
}
.email-footer{
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.email-footer input{
  width: 224px;
  height: 50px;
  border: 0;
  border-radius: 30px;
  padding-left: 20px;
  font-size: 14px;
}

.email-footer a{
  background-color: #a73232;
  margin-left: 10px;
  height: 50px;
  width: 100%;
  border: 0;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: ease 0.3s;
}
.email-footer a:hover{
  background-color: #832020;
  cursor: pointer;
}
.fast-icons{
  color: #000000;
  margin-right: 10px;
}
.black{
  color: #000000;
}
@media (max-width: 375px) {   
.footer-copyright{
  padding: 10px 0;
  flex-direction: column;
  align-items: center;
}
.footer-copyright p{
  text-align: center;
}
.footer-icons{
  margin: 0;
  margin-top: 40px;
}
.footer-content{
  flex-direction: column;
  padding: 40px 20px;
}
}
header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: transparent;
  transition: all ease 0.5s;
  padding: 0 20px;
  z-index: 2;
}
header.black{
  background-color: #8f8f8f;

}
.logo-header{
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.header-links{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #fff;
}
.header-links ul{
  display: flex;
  list-style-type: none;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 500;
}
.header-links ul li{
  margin: 0 20px;
  margin-top: 4px;
  border-bottom: 5px solid transparent;
  transition: 0.3s;
}
.link-header-text{
  text-decoration: none;
  color: #fff;
}
.header-links ul li:hover{
  border-bottom: 3px solid #a73232;
  cursor: pointer;
}
header button{
  width: 168px;
  height: 40px;
  border: none;
  border-radius: 15px;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  transition: 0.5s;
  border: 1px solid #fff;
}
header button:hover{
  cursor: pointer;
  background-color: #a73232;
  width: 180px;
}
#menu-icon{
  display: none;
}
.nav-menu {
  background-color: rgba(0, 0, 0, 0.9);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
}
.nav-menu.active {
  right: 0;
  transition: 350ms;
}
header .nav-menu ul{
  list-style: none;
  margin-top: 50px;
  font-size: 16px;
  color: #fff;
  align-items: center;
}
header .nav-menu ul li{
  margin: 18px 0;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  height: 40px;
  width: 230px;
  padding: 10px 15px;
  border-bottom: 1px solid #fff;
}
.nav-icons{
  margin-right: 15px;
}
#exit-icon{
  position: absolute;
  margin-top: 20px;
  margin-left: 150px;
}
@media (max-width: 375px) {
  .logo-header{
   margin-top: 10px;
  }
  .header-links{
    display: none;
  }
  #menu-icon{
    display: inline;
    display: initial;
    margin-bottom: 10px;
    margin-right: 10px;
  }  
}
.home-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.home-content{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
}
.home-content h1{
  align-self: center;
  -webkit-text-decoration: underline #232323;
          text-decoration: underline #232323;
}
.home-content h2{
  align-self: center;
  margin-top: 10px;
}
.home-content p{
  margin-top: 10px;
  font-size: 20px;
  color: #555151;
  text-align: justify;
}
.itens-home{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  width: 100%;
}
.item-home{
  width: 500px;
  margin: 0 30px;
}
.item-home h2{
  margin-left: 20px;
  font-size: 20px;
}
.itens-home cite{
  font-size: 26px;
  font-weight: 600;
}
.item-home .red{
  background-color: #a73232;
}
.item-home .blue{
  background-color: #02696b;
}
.title-item-home{
  display: flex;
  align-items: center;
}
.title-home-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #A6B230;
  border-radius: 50%;
}
.home-about{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px;
}
@media (max-width: 375px) {
  .home-container{
    width: 100%;
  }
  .itens-home{
    flex-direction: column;
    margin-top: 30px;
  }  
  .item-home{
    width: 100%;
    margin-bottom: 40px;
  }
  .home-about{
    padding: 20px;
  }
  .home-content h2{
    margin-top: 10px;
    font-size: 20px;
    margin-left: 30px;
  }
  .title-item-home{
    align-items: center;
  }
  .item-home h2{
    margin-left: 15px;
    margin-bottom: 8px;
  }
  .home-about h1{
    font-size: 25px;
  }
}
.contact-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #c2c2c2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 35% bottom 65%;
}
.contact-container h1{
  font-size: 40px;
  -webkit-text-decoration: underline #a73232;
          text-decoration: underline #a73232;
  margin-top: 200px;
  margin-bottom: 40px;
  color: #37352f;
  text-transform: uppercase;
}
.contact-info{
  display: flex;
  justify-content: space-between;
  width: 95%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 20px 40px; 
  box-shadow: 0px 1px 5px -2px #000000;
}
.contact-icons{
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.email-icons{
  width: 70px;
  height: 50px;
  margin-right: 10px;
}
.maps-info h2{
  margin-top: 20px;
  color: #fff;
  padding-bottom: 20px;
  font-weight: 400;
}
.maps{
  width: 520px;
  height: 500px;
}
.left-side{
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.left-side h2{
  color: #fff;
  font-weight: normal;
  margin-top: 20px;
}
.icon-left{
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.left-item-icons{ 
    box-shadow: 0px 1px 5px -2px #080808;
    padding: 20px;
    margin-top: 20px;
    transition: ease 0.3s;
}
.left-item-icons:hover{
  cursor: pointer;
  background-color: #a73232;
  border-radius: 10px;
}
a{
  text-decoration: none;
  color: #fff;
}
@media (max-width: 375px) {
  .banner-contact h1{
  
  }
  .contact-container h1{
    font-size: 20px;
    -webkit-text-decoration: underline #a73232;
            text-decoration: underline #a73232;
    margin-top: 150px;
    margin-bottom: 40px;
    color: #37352f;
    text-transform: uppercase;
  }
  .contact-info{
    flex-direction: column;
    width: 100%;
    position: static;
    position: initial;
    padding:0;
    margin-top: 0;
  }
  .maps-info{
   align-self: center;
   margin-bottom: 40px;
  } 
  .maps{
    width: 300px;
    height: 300px;
  }
  .left-item{
    margin-bottom: 50px;
  }
  .email-icons{
    width: 50px;
    height: 20px;
    margin-right: 10px;
  }
}
.product-container{
  width: 100%;
  padding: 100px;
  background-color: #c2c2c2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 35% bottom 65%;
}
.product-container h1, h2{
  color: #37352f;
}
.title-products{
  margin-top: 30px;
}
.list-images{
  padding: 20px 0;
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.image-item{
  width: 350px;
  height: 250px;
  padding: 8px;
  margin: 12px;
  transition: all ease 0.3s;
  background-color: #fff;
  
}
.rotate-right{
  width: 360px;
  height: 260px;
  transform: rotate(5deg);
}
.rotate-left{
  width: 360px;
  height: 260px;
  transform: rotate(-5deg);
}
@media (max-width: 375px) {
  .product-container{
    padding: 30px;
  }
  .product-container h1, .product-container h2{
    font-size: 14px;
    color: #fff;
    text-align: center;
  }
  .product-container h1{
    margin-top: 100px;
  }
  .list-images{
    padding: 0;
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.2);
    border-radius: 4px;
  }
  .image-item{
    width: 100%;
  }
}
.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 1000px;
  height: 600px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
