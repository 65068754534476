.banner{
  background-image: url("../../img/novo-fundo.jpg");
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 35% bottom 65%;
  display: flex;
  align-items: flex-end;
  padding: 50px;
  background-color: black;
}
.banner-info{
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
  padding: 40px;
  border-radius: 10px;
}
.banner-info h1{
  font-size: 40px;
  color: #fff;
  width: 460px;
}
.banner-info cite{
  color: #B2B2B2;
  margin: 15px 0;
}
.banner-info button{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  width: 200px;
  height: 40px;
  background-color: #a73232;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  transition: all ease 0.3s;
  margin-top: 10px;
}
.banner-info button:hover{
  background-color: #832020;
  width: 220px;
  cursor: pointer;
}
@media (max-width: 375px) {
  *{
    margin: 0;
    padding: 0;
  }
  .banner{
    width: 100%;
    padding: 0;
  }
  .banner-info{
    margin-bottom: 0;
  }
  .banner-info h1{
    margin-top: 200px;
    width: 100%;
  }
}