header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: transparent;
  transition: all ease 0.5s;
  padding: 0 20px;
  z-index: 2;
}
header.black{
  background-color: #8f8f8f;

}
.logo-header{
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.header-links{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #fff;
}
.header-links ul{
  display: flex;
  list-style-type: none;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 500;
}
.header-links ul li{
  margin: 0 20px;
  margin-top: 4px;
  border-bottom: 5px solid transparent;
  transition: 0.3s;
}
.link-header-text{
  text-decoration: none;
  color: #fff;
}
.header-links ul li:hover{
  border-bottom: 3px solid #a73232;
  cursor: pointer;
}
header button{
  width: 168px;
  height: 40px;
  border: none;
  border-radius: 15px;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  transition: 0.5s;
  border: 1px solid #fff;
}
header button:hover{
  cursor: pointer;
  background-color: #a73232;
  width: 180px;
}
#menu-icon{
  display: none;
}
.nav-menu {
  background-color: rgba(0, 0, 0, 0.9);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
}
.nav-menu.active {
  right: 0;
  transition: 350ms;
}
header .nav-menu ul{
  list-style: none;
  margin-top: 50px;
  font-size: 16px;
  color: #fff;
  align-items: center;
}
header .nav-menu ul li{
  margin: 18px 0;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  height: 40px;
  width: 230px;
  padding: 10px 15px;
  border-bottom: 1px solid #fff;
}
.nav-icons{
  margin-right: 15px;
}
#exit-icon{
  position: absolute;
  margin-top: 20px;
  margin-left: 150px;
}
@media (max-width: 375px) {
  .logo-header{
   margin-top: 10px;
  }
  .header-links{
    display: none;
  }
  #menu-icon{
    display: initial;
    margin-bottom: 10px;
    margin-right: 10px;
  }  
}