.home-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.home-content{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
}
.home-content h1{
  align-self: center;
  text-decoration: underline #232323;
}
.home-content h2{
  align-self: center;
  margin-top: 10px;
}
.home-content p{
  margin-top: 10px;
  font-size: 20px;
  color: #555151;
  text-align: justify;
}
.itens-home{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  width: 100%;
}
.item-home{
  width: 500px;
  margin: 0 30px;
}
.item-home h2{
  margin-left: 20px;
  font-size: 20px;
}
.itens-home cite{
  font-size: 26px;
  font-weight: 600;
}
.item-home .red{
  background-color: #a73232;
}
.item-home .blue{
  background-color: #02696b;
}
.title-item-home{
  display: flex;
  align-items: center;
}
.title-home-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #A6B230;
  border-radius: 50%;
}
.home-about{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px;
}
@media (max-width: 375px) {
  .home-container{
    width: 100%;
  }
  .itens-home{
    flex-direction: column;
    margin-top: 30px;
  }  
  .item-home{
    width: 100%;
    margin-bottom: 40px;
  }
  .home-about{
    padding: 20px;
  }
  .home-content h2{
    margin-top: 10px;
    font-size: 20px;
    margin-left: 30px;
  }
  .title-item-home{
    align-items: center;
  }
  .item-home h2{
    margin-left: 15px;
    margin-bottom: 8px;
  }
  .home-about h1{
    font-size: 25px;
  }
}