.contact-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #c2c2c2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 35% bottom 65%;
}
.contact-container h1{
  font-size: 40px;
  text-decoration: underline #a73232;
  margin-top: 200px;
  margin-bottom: 40px;
  color: #37352f;
  text-transform: uppercase;
}
.contact-info{
  display: flex;
  justify-content: space-between;
  width: 95%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 20px 40px;
  -webkit-box-shadow: -1px 9px 10px -2px #000000; 
    box-shadow: 0px 1px 5px -2px #000000;
}
.contact-icons{
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.email-icons{
  width: 70px;
  height: 50px;
  margin-right: 10px;
}
.maps-info h2{
  margin-top: 20px;
  color: #fff;
  padding-bottom: 20px;
  font-weight: 400;
}
.maps{
  width: 520px;
  height: 500px;
}
.left-side{
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.left-side h2{
  color: #fff;
  font-weight: normal;
  margin-top: 20px;
}
.icon-left{
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.left-item-icons{
    -webkit-box-shadow: -1px 9px 10px -2px #080808; 
    box-shadow: 0px 1px 5px -2px #080808;
    padding: 20px;
    margin-top: 20px;
    transition: ease 0.3s;
}
.left-item-icons:hover{
  cursor: pointer;
  background-color: #a73232;
  border-radius: 10px;
}
a{
  text-decoration: none;
  color: #fff;
}
@media (max-width: 375px) {
  .banner-contact h1{
  
  }
  .contact-container h1{
    font-size: 20px;
    text-decoration: underline #a73232;
    margin-top: 150px;
    margin-bottom: 40px;
    color: #37352f;
    text-transform: uppercase;
  }
  .contact-info{
    flex-direction: column;
    width: 100%;
    position: initial;
    padding:0;
    margin-top: 0;
  }
  .maps-info{
   align-self: center;
   margin-bottom: 40px;
  } 
  .maps{
    width: 300px;
    height: 300px;
  }
  .left-item{
    margin-bottom: 50px;
  }
  .email-icons{
    width: 50px;
    height: 20px;
    margin-right: 10px;
  }
}