.product-container{
  width: 100%;
  padding: 100px;
  background-color: #c2c2c2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 35% bottom 65%;
}
.product-container h1, h2{
  color: #37352f;
}
.title-products{
  margin-top: 30px;
}
.list-images{
  padding: 20px 0;
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.image-item{
  width: 350px;
  height: 250px;
  padding: 8px;
  margin: 12px;
  transition: all ease 0.3s;
  background-color: #fff;
  
}
.rotate-right{
  width: 360px;
  height: 260px;
  transform: rotate(5deg);
}
.rotate-left{
  width: 360px;
  height: 260px;
  transform: rotate(-5deg);
}
@media (max-width: 375px) {
  .product-container{
    padding: 30px;
  }
  .product-container h1, .product-container h2{
    font-size: 14px;
    color: #fff;
    text-align: center;
  }
  .product-container h1{
    margin-top: 100px;
  }
  .list-images{
    padding: 0;
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.2);
    border-radius: 4px;
  }
  .image-item{
    width: 100%;
  }
}