footer{
  width: 100%;
  background-color: #e2e2e2;
  color: rgb(20, 19, 19);
  border-top: 0.1em solid #989898;
}
.footer-content{
  display: flex;
  padding: 40px 20px;
}
.footer-item{
  padding: 20px;
  width: 100%;
  margin-top: 30px;
}
.footer-item h2{
  margin-bottom: 20px;
}
.footer-item p{
 text-align: justify;
}
.footer-item address{
  font-weight: bold;
  margin-top: 20px;
}
.phone-item{
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-weight: normal;
}
.phone-footer-icon{
  margin-right: 10px;
}
.footer-item ul{
  list-style-type: none;
}
.footer-item ul li{
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #232323;
  transition: 0.3s;
  color: #000000;
}
.footer-item ul li:hover{
  color: #a73232;
  cursor: pointer;
}
.footer-copyright{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  font-weight: normal;
  color: #000;
  border-top: 0.1em solid #989898;
}
.footer-icons{
  margin-right: 10px;
  display: flex;
}
.footer-icons a{
  color: #000000;
}
.footer-icon{
  margin-right: 20px;
  transition: 0.3s;
  cursor: pointer;
}
.footer-icon:hover{
  color: #a73232;
}
.email-footer{
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.email-footer input{
  width: 224px;
  height: 50px;
  border: 0;
  border-radius: 30px;
  padding-left: 20px;
  font-size: 14px;
}

.email-footer a{
  background-color: #a73232;
  margin-left: 10px;
  height: 50px;
  width: 100%;
  border: 0;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: ease 0.3s;
}
.email-footer a:hover{
  background-color: #832020;
  cursor: pointer;
}
.fast-icons{
  color: #000000;
  margin-right: 10px;
}
.black{
  color: #000000;
}
@media (max-width: 375px) {   
.footer-copyright{
  padding: 10px 0;
  flex-direction: column;
  align-items: center;
}
.footer-copyright p{
  text-align: center;
}
.footer-icons{
  margin: 0;
  margin-top: 40px;
}
.footer-content{
  flex-direction: column;
  padding: 40px 20px;
}
}